<template>
    <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
            <!-- <h6 class="mb-0" v-if="showName == true">{{ getSafe(() => prop_data.command) }} ({{ getSafe(() => device.object)
            }})
                <b-badge v-if="lastTimeMessage != ''">{{ lastTimeMessage }}</b-badge>
            </h6>
            <h6 class="mb-0" v-else>{{ getSafe(() => prop_data.command) }} <b-badge v-if="lastTimeMessage != ''">{{
                lastTimeMessage }}</b-badge></h6> -->

            <h6 class="mb-0">{{ labelOfdata }} in time</h6>

        </template>
        <div class="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless">
            <div class="card-header h-auto border-0">
                <div class="card-title py-5">
                    <h3 class="card-label">
                        <span class="d-block text-dark font-weight-bolder">{{ labelOfdata }}</span>
                        <span class="d-block text-dark-50 mt-2 font-size-sm">This information can be updated in
                            real-time</span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <ul class="nav nav-pills nav-pills-sm nav-dark-75" role="tablist">
                        <li class="nav-item">
                            <b-button :variant="show == 'general' ? 'danger' : ''"
                                v-on:click="downloadData(), show = 'general'" :disabled="dataLoaded == null">
                                <b-spinner small v-show="dataLoaded == null && show == 'general'"></b-spinner>
                                Latest
                            </b-button>
                        </li>
                        <li class="nav-item">
                            <b-button :variant="show == 'month' ? 'danger' : ''"
                                v-on:click="downloadData1M(), show = 'month'" :disabled="dataLoaded == null">
                                <b-spinner small v-show="dataLoaded == null && show == 'month'"></b-spinner>
                                Month
                            </b-button>
                        </li>
                        <li class="nav-item">
                            <b-button :variant="show == 'week' ? 'danger' : ''" v-on:click="downloadData7D(), show = 'week'"
                                :disabled="dataLoaded == null">
                                <b-spinner small v-show="dataLoaded == null && show == 'week'"></b-spinner>
                                Week
                            </b-button>
                        </li>
                        <li class="nav-item">
                            <b-button :variant="show == 'day' ? 'danger' : ''" v-on:click="downloadData24H(), show = 'day'"
                                :disabled="dataLoaded == null">
                                <b-spinner small v-show="dataLoaded == null && show == 'day'"></b-spinner>
                                24 Hour
                            </b-button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card-body">
                <!--begin::Example-->

                <apexchart v-if="prop.indexOf('bar') != -1" class="card-rounded-bottom" :options="chartOptionsBar"
                    :series="dataToShow" type="bar" ref="apexBar"></apexchart>
                <apexchart v-if="prop.indexOf('line') != -1" ref="apexLine" class="card-rounded-bottom" type="line"
                    :options="chartOptionsLine" :series="dataToShow">
                </apexchart>
                <!--end::Example-->
            </div>
        </div>
    </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
    //   FETCH_CHART_DATA,
    FETCH_CHART_DATA_24H,
    FETCH_CHART_DATA_7D,
    FETCH_CHART_DATA_1M
} from "@/core/services/store/devices.module";

export default {
    data() {
        return {
            unsubscribe: null,
            show: 'general',
            prop_data: {},
            dataLoaded: null,
            chartOptionsBar: {},
            chartOptionsLine: {},
            month: [],
            week: [],
            day: [],
            general: [],
            lastTimeLoaded: -1,
            lastTimeMessage: "",
        }
    },
    methods: {
        getSafe,
        formatDate(dateString) {
            const options = {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            };
            let date;
            try {
                date = new Date(dateString);
                if (isNaN(date)) {
                    throw new Error('Invalid date');
                }
                return new Intl.DateTimeFormat(undefined, options).format(date);
            } catch (err) {
                console.error('Error formatting date:', err);
                return null;
            }
        },
        resolveComplexValues(destinationArray, valuesArrayObject) {
            const compound_values = valuesArrayObject.map((element) => element.value);
            const xaxis_values = valuesArrayObject.map((element) => this.formatDate(element.created_at));
            // console.log("compound_values",compound_values);
            // first extract the 1st value to decide if we move it directly to a single array or several
            if (compound_values[0] != undefined && compound_values[0] != null && compound_values[0].indexOf(",") != -1) {
                console.log("stage_1");
                // we are talking about a chart that should display several values
                const complex_values_splited = compound_values[0].split(",");
                if (complex_values_splited.length == 2) {
                    destinationArray.push({ name: this.prop_data.command + " X", data: compound_values.map((element) => Number(element.split(",")[0])) });
                    destinationArray.push({ name: this.prop_data.command + " Y", data: compound_values.map((element) => Number(element.split(",")[1])) });
                } else if (complex_values_splited.length == 3) {
                    destinationArray.push({ name: this.prop_data.command + " X", data: compound_values.map((element) => Number(element.split(",")[0])) });
                    destinationArray.push({ name: this.prop_data.command + " Y", data: compound_values.map((element) => Number(element.split(",")[1])) });
                    destinationArray.push({ name: this.prop_data.command + " Z", data: compound_values.map((element) => Number(element.split(",")[2])) });
                } else {
                    // for the moment only support up to 3 different values inside
                }
            } else {
                console.log("stage_2_1");
                const compound_values_splited = compound_values.map((element) => {
                    return Number(element);
                });
                destinationArray.push({ name: this.labelOfdata, data: compound_values_splited.reverse(), xaxis: xaxis_values.reverse() });
            }
            // console.log("destinationArray",destinationArray);
        },
        updateLineChart(dataset) {
            if (this.prop.indexOf("line") != -1) {
                this.$refs.apexLine.updateOptions({
                    xaxis: {
                        categories: dataset[0].xaxis,
                    },
                });
                // this.chartOptionsLine.series = this.general;
                this.$refs.apexLine.updateOptions({
                    series: dataset,
                });
            } else if (this.prop.indexOf("bar") != -1) {
                this.$refs.apexBar.updateOptions({
                    xaxis: {
                        categories: Array.from({ length: dataset[0].data.length }, (_, i) => i + 1),
                    },
                });
                // this.chartOptionsLine.series = this.general;
            }
        },
        downloadData() {
            this.dataLoaded = null;
            // console.log("data_less====>", this.dataset);
            this.general = [];
            console.log(this.general);
            // download devices list
            this.prop_data = this.dataset;
            this.dataLoaded = this.dataset;
            this.resolveComplexValues(this.general, this.dataset);
            this.updateLineChart(this.general);
            console.log("treated_less===>", this.general);

        },
        downloadData24H() {
            if (this.device != null && this.day.length == 0) {
                this.dataLoaded = null;
                this.$store
                    .dispatch(FETCH_CHART_DATA_24H, { device_token: this.device.token, prop_id: this.prop.substr(this.prop.indexOf(":") + 1) })
                    // go to which page after successfully login
                    .then((data) => {
                        this.day = [];
                        // download devices list
                        this.dataLoaded = data;
                        this.prop_data = data;
                        this.resolveComplexValues(this.day, this.prop_data.values.reverse());
                        this.updateLineChart(this.day);
                    });
            }
        },
        downloadData7D() {
            if (this.device != null && this.week.length == 0) {
                this.dataLoaded = null;
                this.$store
                    .dispatch(FETCH_CHART_DATA_7D, { device_token: this.device.token, prop_id: this.prop.substr(this.prop.indexOf(":") + 1) })
                    // go to which page after successfully login
                    .then((data) => {
                        this.week = [];
                        // download devices list
                        this.dataLoaded = data;
                        this.prop_data = data;
                        this.resolveComplexValues(this.week, this.prop_data.values.reverse());
                        this.updateLineChart(this.week);
                    });
            }
        },
        downloadData1M() {
            if (this.device != null && this.month.length == 0) {
                this.dataLoaded = null;
                this.$store
                    .dispatch(FETCH_CHART_DATA_1M, { device_token: this.device.token, prop_id: this.prop.substr(this.prop.indexOf(":") + 1) })
                    // go to which page after successfully login
                    .then((data) => {
                        this.month = [];
                        // download devices list
                        this.dataLoaded = data;
                        this.prop_data = data;
                        this.resolveComplexValues(this.month, this.prop_data.values.reverse());
                        this.updateLineChart(this.month);
                    });
            }
        },
    },
    computed: {
        ...mapGetters(["layoutConfig"]),
        dataToShow() {
            console.log("dataToShow", this.show);
            if (this.show === "month") return this.month;
            if (this.show === "week") return this.week;
            if (this.show === "day") return this.day;
            if (this.show === "general") return this.general;
            return this.day;
        }
    },
    components: {
    },
    props: {
        device: null,
        dataset: [],
        labelOfdata: {
            type: String,
            default: 'ChartTitle'
        },
        prop: {
            type: String,
            default: 'bar-chart:20'
        },
        showName: {
            type: Boolean,
            default: false
        },
    },
    beforeDestroy() {
    // Unsubscribe from the store when the component is destroyed
    if (this.unsubscribe) {
      this.unsubscribe();console.log("kill_subscribe");
    }
  },
    mounted() {
        this.downloadData();
        this.unsubscribe=this.$store.subscribe((mutation, state) => {
            if (mutation.type == "setMessage") {
                // verify if the type of message is for this widget
                if (
                    this.device != null &&
                    state.realtime.last_message.token == this.device.token &&
                    state.realtime.last_message.type == "prop" &&
                    Number(state.realtime.last_message.id) ==
                    Number(this.prop.substr(this.prop.indexOf(":") + 1))
                ) {
                    console.log("real_time_chart", state.realtime.last_message);
                    // call again the action to get the status
                    // this.downloadData();
                    const this_time = new Date();
                    this.lastTimeLoaded = this_time.getTime();
                    this.prop_data.values.push({ value: state.realtime.last_message.value });
                    this.prop_data.values.shift();
                    switch (this.show) {
                        case "general":
                            this.general = [];
                            console.log("selecting_general");
                            // this.general.push({name:this.prop_data.command , data: this.prop_data.values.map((element)=>element.value)});
                            //this.resolveComplexValues(this.general, this.prop_data.values);
                            break;
                        case "day":
                            this.day = [];
                            console.log("selecting_day");
                            // this.day.push({name:this.prop_data.command , data: this.prop_data.values.map((element)=>element.value)});
                            //this.resolveComplexValues(this.day, this.prop_data.values);
                            break;
                        case "week":
                            this.week = [];
                            // this.week.push({name:this.prop_data.command , data: this.prop_data.values.map((element)=>element.value)});
                            //this.resolveComplexValues(this.week, this.prop_data.values);
                            console.log("selecting_week");
                            break;
                        case "month":
                            this.month = [];
                            // this.month.push({name:this.prop_data.command , data: this.prop_data.values.map((element)=>element.value)});
                            //this.resolveComplexValues(this.month, this.prop_data.values);
                            console.log("selecting_month");
                            break;

                    }
                }
            }
        });
        setInterval(() => {
            if (this.lastTimeLoaded == -1) {
                // this.lastTimeMessage = "loading ...";
            } else {
                const this_time1 = new Date();
                const elapsed_seconds = Math.ceil(
                    (this_time1 - this.lastTimeLoaded) / 1000
                );
                if (elapsed_seconds < 60) {
                    this.lastTimeMessage = String(elapsed_seconds) + " seconds ago";
                } else {
                    this.lastTimeMessage =
                        String(Math.floor(elapsed_seconds / 60)) + " minutes ago";
                }
            }
        }, 1000);
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Vuetify", route: "alerts" },
            { title: "Alerts" }
        ]);

        this.chartOptionsBar = {
            chart: {
                type: "bar",
                height: 350,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: ["30%"],
                    endingShape: "rounded"
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"]
            },
            xaxis: {
                categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    style: {
                        colors: this.layoutConfig("colors.gray.gray-500"),
                        fontSize: "12px",
                        fontFamily: this.layoutConfig("font-family")
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: this.layoutConfig("colors.gray.gray-500"),
                        fontSize: "12px",
                        fontFamily: this.layoutConfig("font-family")
                    }
                }
            },
            fill: {
                opacity: 1
            },
            states: {
                normal: {
                    filter: {
                        type: "none",
                        value: 0
                    }
                },
                hover: {
                    filter: {
                        type: "none",
                        value: 0
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: "none",
                        value: 0
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: "12px",
                    fontFamily: this.layoutConfig("font-family")
                },
                y: {
                    formatter: function (val) {
                        return " " + val + " ";
                    }
                }
            },
            colors: [
                this.layoutConfig("colors.theme.base.warning"),
                this.layoutConfig("colors.gray.gray-300")
            ],
            grid: {
                borderColor: this.layoutConfig("colors.gray.gray-200"),
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true
                    }
                }
            }
        };
        this.chartOptionsLine = {
            chart: {
                height: 350,
                type: 'line',
            },
            forecastDataPoints: {
                count: 7
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            xaxis: {
                categories: [0],
                tickAmount: 10,
            },
            title: {
                text: 'Forecast',
                align: 'left',
                style: {
                    fontSize: "16px",
                    color: '#666'
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    gradientToColors: ['#FDD835'],
                    shadeIntensity: 1,
                    type: 'horizontal',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                },
            },
            yaxis: {
                min: -10,
                max: 40
            }
        };
    },
    watch: {
        device: function (oldval, newval) {
            oldval, newval;
            //this.downloadData();
        },
        dataset: function (oldval, newval) {
            oldval, newval;
            this.downloadData();
        },
    },
};
function getSafe(fn) {
    try { return fn(); }
    catch (e) {
        // happy lint
    }
}
</script>
